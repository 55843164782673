import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import $ from "jquery"

import Layout from "../components/js/layout"
import SEO from "../components/js/seo"

import "../components/css/post/lecture-post.css"
import "../components/css/markdown.css"

import LectureGraphqlApollo from "./../data/lectures/graphql-apollo.yaml"
import LectureRxJS from "./../data/lectures/rxjs.yaml"
import LectureSQL from "./../data/lectures/sql.yaml"
import LectureSQLAdv from "./../data/lectures/sql-adv.yaml"
import LectureGitGithub from "./../data/lectures/git-github.yaml"
import LectureGitGithubDive from "./../data/lectures/git-github-dive.yaml"
import LectureHtmlCss from "./../data/lectures/html-css.yaml"
import LectureHtmlCssScoop from "./../data/lectures/html-css-scoop.yaml"
import LectureJavascript from "./../data/lectures/javascript.yaml"
import LectureJavascriptAbyss from "./../data/lectures/javascript-abyss.yaml"

import copy from 'copy-to-clipboard';
import toastr from 'toastr'

import yalcoBook from "./../images/interface/yalco-book-1.png"

const LecturePostTemplate = ({ data, location }) => {

  const [titlePageOn, setTitlePage] = useState(false)

  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title

  const lectures = {
    'graphql-apollo': LectureGraphqlApollo,
    'rxjs': LectureRxJS,
    'sql': LectureSQL,
    'sql-adv': LectureSQLAdv,
    'git-github': LectureGitGithub,
    'git-github-dive': LectureGitGithubDive,
    'html-css': LectureHtmlCss,
    'html-css-scoop': LectureHtmlCssScoop,
    'javascript': LectureJavascript,
    'javascript-abyss': LectureJavascriptAbyss
  }

  const lectureCode = location.pathname.split('/')[1].replace('@', '')

  const lecture = lectures[
    lectureCode
  ]

  const sectionAndLessons = []
  lecture.sections.forEach((section) => {
    section.lessons.forEach((lesson) => {
      sectionAndLessons.push({
        code: `${section.idx}-${lesson.idx}`,
        section: section,
        lesson: lesson
      })
    })
  })

  const curLessonCode = location.pathname.split('/')[2]

  const indexOfLesson = sectionAndLessons
  .map(sectionAndLesson => sectionAndLesson.code)
  .indexOf(curLessonCode)

  const section = sectionAndLessons[indexOfLesson].section
  const lesson = sectionAndLessons[indexOfLesson].lesson

  let nextSectionAndLesson
  if (indexOfLesson < sectionAndLessons.length - 1) {
    nextSectionAndLesson = sectionAndLessons[indexOfLesson + 1]
  }

  const insertLectureLink = () => (
    <a href={`/lectures/${location.pathname.split('/')[1].replace('@', '')}`}>
      <div id="lectureTitle">
        <span className="material-icons">home</span>
        강의 홈으로
      </div>
    </a>
  )

  useEffect(() => {
    const slideCons = $('.slideCon')
    for (let i = 0; i < slideCons.length; i++) {
      const slideCon = $(slideCons[i])
      const slides = slideCon.find('.slide')
      if (slides && slides.length && slides.length > 0) {
        $(slides[i]).addClass('on')
        slideCon.find('.page').text(`1/${slides.length}`)

        slideCon.on('click', () => {
          let onIndex
          for (let j = 0; j < slides.length; j++) {
            if ($(slides[j]).hasClass('on')) {
              onIndex = (j + 1) % slides.length
            }
            $(slides[j]).removeClass('on')
          }
          if ($($(slides[onIndex])[0])) {
            $($(slides[onIndex])[0]).addClass('on')
          }
          slideCon.find('.page').text(`${onIndex + 1}/${slides.length}`)
        })
        slideCon.find('a').on('click', e => { e.stopPropagation() })
      }
    }

    const showcases = $('.showcase')
    for (let i = 0; i < showcases.length; i++) {
      const showcase = $(showcases[i])
      showcase.css('width', '100%')
      showcase.css('height', showcase.data('height') + 'px')

      const visit = $(
        `<div class="showcase-visit">
          <a href="${showcase.attr('src')}" target="_blank">
          <span class="material-icons">
            add_to_queue
          </span>
          새 창에서 열기
          </a>
        </div>`
      )
      showcase.after(visit)
    }
    
    $('deckgo-highlight-code').off('click').on('click', (e) => {
      const text = $(e.target).find('code').text()
      copy(text);
      toastr.success('코드가 복사되었습니다.')
    })
  })

  const getStartSec = (str) => {
    const splitted = str.split(':').map((piece) => {
      return Number(piece)
    })
    if (splitted.length === 3) {
      return splitted[0] * 3600 + splitted[1] * 60 + splitted[2]
    } else {
      return splitted[0] * 60 + splitted[1]
    }
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={lesson.title}
        description={lesson.description}
      />

      {
        !titlePageOn && (

          <div className={`lesson-page ${lecture.hide_toc ? 'hide-toc' : ''}`}>
            <aside>
              {/* <a href="/book" rel="noreferrer" target="_blank">
                <img src={yalcoBook} alt="혼공얄코"></img>
              </a> */}
              {
                lecture.sections.map((section, sKey) => (
                  <article className="sec" key={sKey}>
                    <h1>섹션 {section.idx}. {section.title}</h1>
                    <div className="lesson-list">
                      <ul>
                        {
                          section.lessons.map((lesson, lKey) => (
                              <li
                              key={lKey}
                              className={(`${section.idx}-${lesson.idx}` === curLessonCode)
                              ? 'on' : ''}
                              >
                                <a href={`/@${lectureCode}/${section.idx}-${lesson.idx}`}>
                                  {lesson.idx}강. {lesson.title}
                                </a>
                              </li>
                          ))
                        }
                      </ul>
                    </div>
                  </article>
                ))
              }

            </aside>
            <div className="contents">
              <section>

                {insertLectureLink()}

                {
                  !section.finale && 
                  <div id="sectionInfo">Section {section.idx}. {section.title}</div>
                }    
                {
                  section.finale &&
                  <div id="sectionInfo">{section.title}</div>
                }    
                <div
                id="lessonInfo"
                role="button"
                onClick={ () => { setTitlePage(true) } }
                onKeyDown={ () => { setTitlePage(true) } }
                tabIndex={0}
                >
                  Lesson {lesson.idx}. {lesson.title}
                </div>
              </section>
              {
                lecture.youtube && lesson.start && (
                  <iframe 
                    id="video-iframe" 
                    title={lesson.title}
                    src={`https://www.youtube.com/embed/${
                      lecture.youtube
                    }?start=${getStartSec(lesson.start)}`} 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                  </iframe>
                )
              }
              <article className="post markdown-body">
                <section dangerouslySetInnerHTML={{ __html: post.html }} />
              </article>
              <section className="centered">
                <div id="askYalco">
                  <h1>
                    <span role="img" aria-label="ask yalco">🤔</span>
                    <span>얄코에게 질문하기</span>
                    <i>질문은 반.드.시 이리로 보내주세요! ( 강의사이트 질문기능 ✖ )</i>
                  </h1>
                  <div className="desc-n-contact">
                    <p className="desc">
                      강의에서 이해가 안 되거나 실습상 문제가 있는 부분,<br/>
                      설명이 잘못되었거나 미흡한 부분을 메일로 알려주세요!<br/>
                      <br/>
                      답변드린 뒤 필요할 경우 본 페이지에<br/>
                      관련 내용을 추가/수정하도록 하겠습니다.
                    </p>
                    <div className="contact">
                      <div className="item">
                        이메일 주소 
                      </div>
                      <div className="content">
                        yalco@yalco.kr
                        {/* <span className="vacation">
                          <span role='img' aria-label="vacation">⛱️</span> 2022/6/7~9일까지 휴가입니다.
                        </span> */}
                      </div>
                      <div className="item">메일 제목 <span>(반드시 아래 제목을 붙여넣어주세요!)</span></div>
                      <div className="content">[질문] {lecture.title} {section.idx}-{lesson.idx}</div>
                    </div>
                  </div>
                  <div className="before-ask">
                    <h2>
                      <span role="img" aria-label="warning">🛑</span>
                      질문 전 필독!!
                    </h2>
                    <ul>
                      <li>
                        <b>구글링</b>을 먼저 해 주세요. 들어오는 질문의 절반 이상은 구글에 검색해 보면 1분 이내로 답을 찾을 수 있는 내용들입니다.
                      </li>
                      <li>
                        <b>오류 메시지</b>가 있을 경우 이를 <b>구글에 복붙해서 검색</b>해보면 대부분 짧은 시간 내 해결방법을 찾을 수 있습니다.
                      </li>
                      <li>
                        강의 페이지에 추가사항 등 <b>놓친 부분</b>이 없는지 확인해주세요. 자주 들어오는 질문은 페이지에 <b>추가사항</b>으로 업데이트됩니다.
                      </li>
                      <li>
                        "<b>유료파트의 강의페이지</b>는 어디 있나요?" - 각 영상의 <b>시작부분 검은 화면마다 해당 챕터의 강의페이지 링크</b>가 있습니다.
                      </li>
                      <li>
                        질문을 보내주실 때는 문제가 어떻게 발생했고 어떤 상황인지 등을 <b>구체적으로</b> 적어주세요. 스크린샷을 첨부해주시면 더욱 좋습니다.
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section>
                {
                  nextSectionAndLesson && (
                    <div className="prevNextLink">
                      <a href={
                        `/${location.pathname.split('/')[1]}/${nextSectionAndLesson.code}`
                        }>
                        <span>⬇ 다음</span>
                        {nextSectionAndLesson.code}. {nextSectionAndLesson.lesson.title}
                      </a>

                    </div>
                  )
                }
              </section>
            </div>
          </div>
        )
      }
      {
        titlePageOn && (
          <section
          id="titlePage"
          role="button"
          onClick={ () => { setTitlePage(false) } }
          onKeyDown={ () => { setTitlePage(false) } }
          tabIndex={0}
          >
            <div class="titles">
              <h2>Lesson {lesson.idx}.</h2>
              <h1>{lesson.title}</h1>
              <span className="link">
                https://www.yalco.kr/@
                <span>{lecture.alias}</span>
                /{section.idx}-{lesson.idx}
              </span>
            </div>
            <div className="leftTop">
              <h3>
                <span>Section {section.idx}.</span>
                {section.title}
              </h3>
            </div>
          </section>
        )
      }
      
    </Layout>
  )
}

export default LecturePostTemplate

export const pageQuery = graphql`
  query LecturePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        idx
      }
    }
  }
`